/* eslint-disable no-unused-vars */
import { Language, ShoeSizeSystem, UnitSystem } from '../frontend/enums/languageEnums';

interface SettingsType {
    agbAccepted?: boolean;
    newsletterAccepted?: boolean;
    preferredLanguage?: Language;
    preferredUnitSystem?: UnitSystem;
    preferredShoeSizeSystem?: ShoeSizeSystem;
    email: string;
    name?: string;
    defaultPayoutMethod?: string;
    paypalPayoutMethod: boolean;
    stripePayoutMethod: boolean;
    payoutLast4?: string;
    paypalEmailMasked?: string;
    ownAffiliationPartnerId: string;
    payoutAddress?: AddressType;
    vatId?: string;
    shopDomains: string[];
    disconnectedShopDomains?: string[];
    shops: { [key: string]: ShopSettings };
}

interface ShopSettings {
    shop_address: string;
    shop_default_payment_method?: string;
    shop_domain: string;
    shop_email?: string;
    shop_name?: string;
    shop_notification_payment_failed: boolean;
    shop_notification_personalized_design: boolean;
    shop_paypal_configured: boolean;
    shop_phone?: string;
    shop_stripe_configured: boolean;
    onboardingFlowCompleted?: boolean;
    isConnected: boolean;
    isInstalled: boolean;
    disconnectedAt?: Date;
    shop_last4?: string;
    productLanguage?: Language;
    productUnitSystem?: UnitSystem;
    productShoeSizeSystem?: ShoeSizeSystem;
    apiToken?: string;
    integrationApiInfos: {
        [key: string]: {
            //key is one of Object.values(INTEGRATION_API_PARTNER)
            webhookUrl: string;
        };
    };
    autoAvailabilityUpdate?: boolean;
    taxExempt?: boolean;
    vatId?: string;
    shop_owner?: string;
    invoiceAddress?: AddressType;
    returnAddress?: AddressType;
    shopIntegrationType: EShopBrand;
}

interface AddressType {
    address1: string;
    address1_street: string;
    address1_number: string;
    city: string;
    country_name: string;
    name?: string;
    shop_owner: string;
    zip: string;
    countryCode: InvoiceAddressCountries;
}

enum INTEGRATION_API_PARTNER {
    TEE_IN_BLUE = 'teeinblue',
}

enum InvoiceAddressCountries {
    AE = 'AE',
    AT = 'AT',
    BE = 'BE',
    BG = 'BG',
    CH = 'CH',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    EE = 'EE',
    ES = 'ES',
    FI = 'FI',
    FR = 'FR',
    GB = 'GB',
    GR = 'GR',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IT = 'IT',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MT = 'MT',
    NO = 'NO',
    NL = 'NL',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    SE = 'SE',
    SI = 'SI',
    SK = 'SK',
    UAE = 'UAE',
    US = 'US',
}

enum EShopBrand {
    SHOPIFY = 'shopify',
    ETSY = 'etsy',
    WOOCOMMERCE = 'woocommerce',
    AMAZON = 'amazon',
}

export { SettingsType, ShopSettings, AddressType, INTEGRATION_API_PARTNER, InvoiceAddressCountries, EShopBrand };
